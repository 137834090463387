@import "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700&display=swap";
.bold {
  font-weight: 700;
}

.regular {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.normal {
  font-style: normal;
}

.content {
  width: 100%;
  margin-top: 180px;
}

.content > * {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

a > span {
  color: #4f4f4f;
  border-bottom: 1px solid #0000;
  transition: border-bottom .2s ease-in-out;
}

a > span:hover {
  border-bottom: 1px solid #4f4f4f;
}
/*# sourceMappingURL=intro.5db51873.css.map */
